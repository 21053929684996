import React from "react"
import Navbar from "../components/navbar"
import styled from "styled-components"

import heroku from "../../static/icons/heroku.svg"
import sqlite from "../../static/icons/sqlite.svg"
import flask from "../../static/icons/flask.svg"
import cs50 from "../../static/icons/cs50.svg"
import git from "../../static/icons/git.svg"
import html from "../../static/icons/html.svg"
import python from "../../static/icons/python.svg"
import javascript from "../../static/icons/javascript.svg"


const ProjectContainer = styled.div`

`

const ProjectTechStackContainer = styled.div`

`

export default function Crowley() {
    const badges = [heroku, python, javascript, html, git, flask, sqlite, cs50];
    return (
      <>
          <Navbar></Navbar>
          <ProjectContainer>
            <ProjectTechStackContainer>
              {badges.map((value, index) => {
                return <img src={value} alt="" width="40" height="40"></img>
              })}
              
            </ProjectTechStackContainer>
          </ProjectContainer>
      </>
    )
  }